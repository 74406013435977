import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/reset.css';
import './assets/css/colors.css';
import './assets/css/layout.css';
import './designSystem.css';
import './assets/css/typography.css';
import './index.css';
import App from './App';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react'
import { UserProvider } from './assets/context/UserContext';
import { GameProvider } from './assets/context/GameContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <Main />
  // </React.StrictMode>
);

function Main() {

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <UserProvider>
          <GameProvider>
            <App />
          </GameProvider>       
         </UserProvider>
      </BrowserRouter>
    </Suspense>
  )
}