import React, { useEffect, useState } from 'react';
import { createContext } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from './UserContext';

const GameContext = createContext('light');


function GameProvider({ children }) {

    let [gameMeta, setGameMeta] = React.useState(null)
    let { user, setUser } = React.useContext(UserContext)
    let [resultIn, setResultIn] = useState(0)

    useEffect(() => {
        (async () => {

            if (user !== null && gameMeta === null) {

                updateMetadata(user.id)
            }




        })()

    }, [user])
    let [customTimeout, setCustomTimeout] = useState(null);
    let interval;
    useEffect(() => {
        (async () => {

            if (resultIn != 0) {
                if (interval) {
                    clearInterval(interval)
                }
                interval = setInterval(() => {
                    setResultIn((prev) =>{
                        let vet = (60-prev)
                        // if(vet <= timerInSeconds)
                        if (prev<=0 || vet == 15) {
                            clearInterval(interval)
                            updateMetadata(user.id)
                        }
                       
                        return prev-1
                    })
                    // console.log(resultIn,'00000000');


                }, (1000));
            }

      
        })()
    }, [gameMeta])
//    useEffect(()=>{
//     console.log(resultIn);
    
//    },[resultIn])
    async function updateMetadata(fromId) {

        console.log();

        let url = `https://cryptoapi.online/metadata?fromId=${fromId}`
        console.log('updated gamemeta', url);

        fetch(url).then((res) => res.json()).then(async (data) => {
            let { closeTime, distribution } = await data
            let resultIn = Math.floor((closeTime - Date.now()) / 1000)
            setResultIn(resultIn)
            
            setGameMeta(data)
        })
    }


    return (
        <GameContext.Provider value={{ gameMeta, setGameMeta, resultIn }}>
            {children}
        </GameContext.Provider>
    )
}

export { GameContext, GameProvider }