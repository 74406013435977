import React, { useEffect } from 'react';
import { createContext } from 'react';
import { useLocation } from 'react-router-dom';
import verifyUser from '../js/verifyUser';

const UserContext = createContext('light');


function UserProvider({children}) {

    let [user, setUser] = React.useState(null)
   let queryParamsObject = {}
    const useQuery = () => {
    const { search } = useLocation();
    return new URLSearchParams(search);
  };


  const query = useQuery();

  useEffect(() => {
    (async () => {
        await query.forEach((value, key) => {
            queryParamsObject[key] = value;
        });
  
        if (queryParamsObject) {
         await verifyUser(queryParamsObject).then((res) => res.json()).then(async (data) => {
             console.log('UserContext updating...')
              setUser(queryParamsObject);   
            //  user = queryParamsObject

         }) .catch((err) => {
          console.log(err);
       })
      }
    })()
  }, [])

    return(
        <UserContext.Provider value={{user, setUser}}>
            {children}
         </UserContext.Provider>
    )
}

export {UserContext, UserProvider}