import { lazy } from "react";

let routesData =[
{
    path: '/',
    component: lazy(() => import('./pages/HomeScreen/HomeScreen'))
},

{
    path: '/referral',
    component: lazy(() => import('./pages/ReferralScreen/ReferralScreen'))
},
{
    path: '/vote',
    component: lazy(() => import('./pages/VoteScreen/VoteScreen'))
},
{
    path: '/login',
    component: lazy(() => import('./pages/LoginScreen/LoginScreen'))
},
{
    path: '/FAQ',
    component: lazy(() => import('./pages/FAQScreen/FAQScreen'))
}
]
// FAQScreen
export default routesData;