import logo from './logo.svg';
import './App.css';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import routesData from './routesData';
import React, { useEffect } from 'react';
import { GameContext } from './assets/context/GameContext';
import { UserContext } from './assets/context/UserContext';
import verifyUser from './assets/js/verifyUser';

function App() {

  let {gameMeta,setGameMeta} = React.useContext(GameContext)
  let {user,setUser} = React.useContext(UserContext)
  
  let queryParamsObject = {}
    const useQuery = () => {
    const { search } = useLocation();
    return new URLSearchParams(search);
  };

  const query = useQuery();

  useEffect(() => {
    (async () => {
        await query.forEach((value, key) => {
            queryParamsObject[key] = value;
        });
  
        if (queryParamsObject) {
         await verifyUser(queryParamsObject).then((res) => res.json()).then(async (data) => {
            //  console.log(queryParamsObject)
              setUser(queryParamsObject);   
            //  user = queryParamsObject

         }) .catch((err) => {
          console.log(err);
       })
      }
    })()
  }, [user === null])


       
   if (user !== null && gameMeta === null) {
    if (user?.id) {
     
    }
   }

  //  console.log(gameMeta, 'App.js');
   

    
  return (
    <div className='App' >

      <Routes>
        {
          routesData.map((item, index) => {
            return <Route key={item.path} path={item.path} element={<item.component />} />
          })
        }

      </Routes>

    </div>
  );
}

export default App;



//   let {user, setUser} = React.useContext(UserContext)
//   let {gameMeta, setGameMeta} = React.useContext(GameContext)

//   const useQuery = () => {
//     const { search } = useLocation();
//     return new URLSearchParams(search);
//   };

//   const query = useQuery();

//   const authDate = query.get('auth_date');
//   const firstName = query.get('first_name');
//   const lastName = query.get('last_name');
//   const id = query.get('id');
//   const photoUrl = query.get('photo_url');
//   const username = query.get('username');
//   const hash = query.get('hash');

//   // Alternatively, get all query parameters as an object
//   const queryParamsObject = {};
//  let [go, setGo] = React.useState(false)        
              
// let navigate = useNavigate();
//   // Retrieve individual query parameter
  
//    useEffect(() => {
//     (async () => {
//       await query.forEach((value, key) => {
//         queryParamsObject[key] = value;
//       });
//       if (queryParamsObject) {
//       await verifyUser(queryParamsObject).then((res) => res.json()).then(async (data) => {
//         console.log(data)
//         const authenticated = data?.authenticated;
//           setUser(queryParamsObject);   
//           console.log('user', user);

//        if(user)
//         {
//          let { id, first_name: firstName, last_name: lastName, username, photo_url: photoUrl, auth_date: authDate, hash } =await  user;
//          let url = `https://cryptoapi.online/login?id=${id}&first_name=${firstName}&last_name=${lastName}&username=${username}&photo_url=${photoUrl}&auth_date=${authDate}&hash=${hash}`;
//          console.log(url);
         
//          fetch(url).then((res) => res.json()).then((data) => {
//            console.log(data)
         
//             //    let ws = new WebSocket('wss://cryptoapi.online/metadata');
                
//             //    ws.onopen = () => {
                 
//             //        let msg = String('{"fromId": ' + user.id + '}')
//             //        console.log(msg);
//             //        ws.send(msg)
//             //        ws.onmessage = (event) => {
//             //          let data = JSON.parse(event.data)
//             //          console.log(data, 'App.js');
//             //          setGameMeta(data)
//             //          ws.close()
//             //          }
             
               
             
//             //  }
             
//           })
  
//         }

//       });
//          }
//     })()
//   }, [user == null])
  //  useEffect(() => {
  //   if (user?.id) {
  //     let ws = new WebSocket('wss://cryptoapi.online/metadata');
       
  //     ws.onopen = () => {
        
  //         let msg = String('{"fromId": ' + user.id + '}')
  //         console.log(msg);
  //         ws.send(msg)
  //         ws.onmessage = (event) => {
  //           let data = JSON.parse(event.data)
  //           console.log(data, 'App.js');
  //           setGameMeta(data)
            
  //         }    
  //     }      
  //   }
  //  },[user?.id])
// useEffect(() => {

//   navigate('/')

// }, [gameMeta])
    
